import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import innovativeIllustration from "../images/undraw_innovative_b409.svg";

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <section className="flex flex-col md:flex-row items-center">
        <div className="md:w-2/3 md:mr-8">
          <blockquote className="border-l-4 border-gray-900 font-serif leading-loose pl-4 text-justify">
          If we are to achieve things never before accomplished we must employ methods never before attempted.
          </blockquote>

          <cite className="font-bold mt-4 text-right text-xs uppercase block">
            – Francis Bacon
          </cite>
        </div>

        <figure className="w-2/3 md:w-1/3">
          <img src={innovativeIllustration} alt="Innovative" />
        </figure>
      </section>
    </Layout>
  );
}

export default AboutPage;
